import deleteCookie from 'core/libs/helpers/deleteCookie';
import getCookie from 'core/libs/helpers/getCookie';
import notification from 'core/libs/helpers/notification';
import emailSignIn from './email-sign-in';

export const emailSignUp = (values) => (dispatch) => {
  const {PARTNER} = process.env;
  const data = new FormData();
  const currentUrl = window.location.href;
  const referringDomain = window.document.referrer;
  const barmejReferralKey = getCookie('barmej_referral_key');
  data.append('first_name', values.first_name);
  data.append('last_name', values.last_name);
  data.append('email', values.email);
  data.append('password', values.password);
  data.append('preferred_track', values.preferred_track);
  data.append('current_url', currentUrl);
  data.append('referring_domain', referringDomain);
  data.append('partner', PARTNER);
  if (barmejReferralKey) data.append('referral_key', barmejReferralKey);

  dispatch({
    types: ['SIGN_UP_START', 'SIGN_UP_COMPLETE', 'SIGN_UP_ERROR'],
    config: {
      method: 'POST',
      url: `users/`,
      data,
    },
    callback: (result, resultData) => {
      if (result) {
        notification({
          id: 'success_sign_up',
          type: 'success',
        });
        dispatch(
          emailSignIn({
            values,
            isSignUp: true,
            preferredTrackSlug: values.preferred_track,
          }),
        );
        if (barmejReferralKey) deleteCookie('barmej_referral_key');
      } else {
        try {
          const message = Object.values(resultData.response.data)
            .map((value) => value.toString())
            .toString();
          notification({message});
        } catch (e) {
          notification({id: 'error_log_out'});
        }
      }
    },
    shouldCallAPI: ({auth}) => !auth.loading,
  });
};

export default emailSignUp;
