/* eslint-disable jsx-a11y/alt-text */
import {Query} from '@apollo/client/react/components';
import cx from 'classnames';
import notification from 'core/libs/helpers/notification';
import parseURLSearch from 'core/libs/helpers/parseURLSearch';
import log from 'core/libs/logger';
import {Link} from 'gatsby';
import Container from 'modules/common/Container';
import Head from 'modules/common/Head';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {
  compose,
  lifecycle,
  setPropTypes,
  withProps,
  withState,
} from 'recompose';
import BarmejLogo from 'theme/assets/images/barmej-logo.svg';
import KfasLogo from 'theme/assets/images/kfas-logo.svg';
import {recordUrlParams} from '../actions/urlParams';
import SignInSocial from '../components/SignInSocial';
import SignUpForm from '../components/SignUpForm';
import TRACKS from '../graphql/tracks.graphql';

const {PARTNER} = process.env;

const SignUp = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {isJavascriptLoading, params, isNormalRegistration} = props;
  return (
    <div>
      <Container className="w-90-ns w-100 center pv4 mb2 ph5-l">
        <div className="tc pb5">
          {PARTNER === 'KFAS' && (
            <img src={KfasLogo} height="100" alt="kfas logo" />
          )}
          {PARTNER === 'BARMEJ' && (
            <img src={BarmejLogo} height="50" alt="barmej logo" />
          )}
        </div>
        {!isNormalRegistration && (
          <div className="tc">
            <h3 className="pb2">
              <FormattedMessage id="welcome_to_this_experiment" />
            </h3>
            <h3 className="pt2 pb4">
              <FormattedMessage id="we_offer_bootcamps" />
            </h3>
            <div className="bt b--blue w-80 w-30-ns center bw2" />
            <h4 className="pv4">
              <FormattedMessage id="register_for_one_of_our_bootcamp" />
            </h4>
          </div>
        )}
        <div className="ba b--light-gray bw1 br2 bg-white">
          <Head
            title={formatMessage({
              id:
                PARTNER === 'BARMEJ'
                  ? 'helmet_sign_up'
                  : 'partner_helmet_sign_up',
            })}
          />
          <Query query={TRACKS} skip={isNormalRegistration}>
            {({data, loading, error}) => {
              if (loading) return <LoadingIndicator />;
              if (error) {
                const errorMessage = error.message;
                log('Error while tracks fetching', {
                  errorMessage,
                  currentUrl:
                    typeof window !== 'undefined' && window.location.href,
                });
                notification({message: errorMessage});
              }
              const tracks = data && data.allTracks && data.allTracks.edges;
              return (
                <>
                  <div className="tc cf">
                    <Link
                      to="/auth/register"
                      className={cx('fr pv4 f5 fw6 black', {
                        'w-50': isNormalRegistration,
                        'w-100': !isNormalRegistration,
                      })}
                    >
                      <FormattedMessage id="button_sign_up" />
                    </Link>
                    {isNormalRegistration && (
                      <Link
                        to="/auth/login"
                        className="fl w-50 pv4 bb bl b--light-gray bw1 bg-near-white gray f5 fw6"
                      >
                        <FormattedMessage id="sign_in" />
                      </Link>
                    )}
                  </div>
                  <div className="w-80-ns w-90 center pv4">
                    <p className="pv4 tc f5-ns f6">
                      <FormattedMessage id="sign_up_email" />
                    </p>
                    <SignUpForm
                      initialValues={params}
                      loading={isJavascriptLoading}
                      isNormalRegistration={isNormalRegistration}
                      tracks={tracks}
                    />
                    {PARTNER === 'BARMEJ' && isNormalRegistration && (
                      <>
                        <p className="pv4 tc f5-ns f6 bt b--near-white">
                          <FormattedMessage id="sign_in_social" />
                        </p>
                        <SignInSocial />
                      </>
                    )}
                  </div>
                </>
              );
            }}
          </Query>
        </div>
      </Container>
    </div>
  );
};

SignUp.propTypes = {
  isJavascriptLoading: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  isNormalRegistration: PropTypes.bool,
};

const enhance = compose(
  connect(),
  withState('isJavascriptLoading', 'setIsJavascriptLoading', true),
  withProps(({location: {search}}) => ({
    params: parseURLSearch(search),
  })),
  setPropTypes({
    location: PropTypes.object.isRequired,
    setIsJavascriptLoading: PropTypes.func.isRequired,
  }),
  lifecycle({
    componentDidMount() {
      const {params, setIsJavascriptLoading, dispatch} = this.props;
      setIsJavascriptLoading(false);
      dispatch(recordUrlParams(params));
    },
  }),
);

export default enhance(SignUp);
