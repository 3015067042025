/* eslint react/prop-types: 0 */
import cx from 'classnames';
import disposableEmail from 'data/disposable-email.json';
import {Field, withFormik} from 'formik';
import FormInputField from 'modules/common/FormInputField';
import ArrowDown from 'modules/common/Svg/ArrowDown';
import Refresh from 'modules/common/Svg/Refresh';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import * as Yup from 'yup';
import {emailSignUp} from '../../actions/email-sign-up';
import {
  customSelect,
  fixedHeight,
  formDropDown,
  loginButton,
  RefreshLoading,
} from '../styles.scss';

export const SignUpForm = ({
  handleSubmit,
  intl: {formatMessage},
  loading,
  tracks,
  isNormalRegistration,
  touched,
  errors,
}) => (
  <form onSubmit={handleSubmit}>
    <div className="pv2 cf">
      <div className="fr w-50-ns w-100 pl3-ns pl0 mb0-ns mb3">
        <Field
          name="first_name"
          component={FormInputField}
          placeholder={formatMessage({id: 'label_first_name'})}
          type="text"
          autoComplete="given-name"
          className={`${fixedHeight} mt2 input-reset pv3 pr3 pl2 dark-gray ba b--light-gray bw1 br2 w-100 tr`}
        />
      </div>
      <div className="fl w-50-ns w-100 pr3-ns pr0">
        <Field
          name="last_name"
          component={FormInputField}
          placeholder={formatMessage({id: 'label_last_name'})}
          type="text"
          autoComplete="family-name"
          className={`${fixedHeight} mt2 input-reset pv3 pr3 pl2 dark-gray ba b--light-gray bw1 br2 w-100 tr`}
        />
      </div>
    </div>
    <div className="pv2">
      <Field
        name="email"
        component={FormInputField}
        placeholder={formatMessage({id: 'label_email_adress'})}
        type="email"
        autoComplete="email"
        className={`${fixedHeight} mt2 input-reset pv3 pr3 pl2 dark-gray ba b--light-gray bw1 br2 w-100 tr`}
      />
    </div>
    <div className="pv2">
      <Field
        name="password"
        component={FormInputField}
        placeholder={formatMessage({id: 'label_password'})}
        type="password"
        autoComplete="new-password"
        className={`${fixedHeight} mt2 input-reset pv3 pr3 pl2 dark-gray ba b--light-gray bw1 br2 w-100 tr`}
      />
    </div>
    {!isNormalRegistration && (
      <div className="pv2">
        <div className="mt3 relative w-100 dark-gray ba b--light-gray bw1 br2">
          <Field
            name="preferred_track"
            component="select"
            className={`${fixedHeight} ${customSelect} ${formDropDown} relative z-1 pr3 pl4 w-100 bg-transparent f6 b--transparent`}
          >
            <option value="">{formatMessage({id: 'select_bootcamp'})}</option>
            {tracks.map(({node: {id, name, slug}}) => (
              <option value={slug} key={id}>
                {name}
              </option>
            ))}
          </Field>
          <ArrowDown color="#666" className="absolute h-100 top-0 left-0 ml2" />
        </div>
        {touched.preferred_track && errors.preferred_track && (
          <div>
            <span className="red db absolute f7 fw3 mt2">
              {errors.preferred_track}
            </span>
          </div>
        )}
      </div>
    )}
    <Field name="track" component={FormInputField} type="hidden" />
    <Field name="utm_source" component={FormInputField} type="hidden" />
    <Field name="utm_medium" component={FormInputField} type="hidden" />
    <Field name="utm_campaign" component={FormInputField} type="hidden" />
    <Field name="utm_content" component={FormInputField} type="hidden" />
    <p className="pv2 tr-ns tc gray f6">
      <FormattedMessage id="accept_tos" />
      <a
        href="https://www.barmej.com/%D8%A7%D9%84%D8%B4%D8%B1%D9%88%D8%B7-%D9%88-%D8%A7%D9%84%D8%A3%D8%AD%D9%83%D8%A7%D9%85/%D8%A7%D9%84%D9%82%D8%A7%D9%86%D9%88%D9%86/"
        target="_blank"
        rel="noopener noreferrer"
        className="blue ph1 dim"
      >
        <FormattedMessage id="terms" />
      </a>
      <FormattedMessage id="what_is_provided_on" />
      <a
        href="https://www.barmej.com/%D8%B3%D9%8A%D8%A7%D8%B3%D8%A9-%D8%A7%D9%84%D8%AE%D8%B5%D9%88%D8%B5%D9%8A%D8%A9/%D8%A7%D9%84%D9%82%D8%A7%D9%86%D9%88%D9%86/"
        target="_blank"
        rel="noopener noreferrer"
        className="blue ph1 dim"
      >
        <FormattedMessage id="privacy" />
      </a>
      <FormattedMessage id="on_the_site" />
    </p>
    <div className="pv4 tc w-80 center">
      <button
        aria-label="Sign up"
        type="submit"
        className={cx(
          'button-reset f7 f5-ns bn bg-blue fw6 white br2 bg-animate hover-bg-dark-blue pointer',
          loginButton,
        )}
        disabled={loading}
      >
        {loading ? (
          <Refresh
            className={RefreshLoading}
            width="16"
            height="16"
            color="#fff"
          />
        ) : (
          <FormattedMessage id="button_sign_up" />
        )}
      </button>
    </div>
  </form>
);

SignUpForm.propTypes = {
  intl: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  tracks: PropTypes.array,
  isNormalRegistration: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
};

const enhance = compose(
  connect((state, {loading}) => ({
    loading: loading || state.auth.loading,
  })),
  injectIntl,
  withFormik({
    mapPropsToValues: (initialValues) => {
      return {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        preferred_track: '',
        track: initialValues.track,
        utm_source: initialValues.utm_source,
        utm_medium: initialValues.utm_medium,
        utm_campaign: initialValues.utm_campaign,
        utm_content: initialValues.utm_content,
      };
    },
    handleSubmit: (values, {props}) => {
      props.dispatch(emailSignUp(values));
    },
    validationSchema: ({intl: {formatMessage}, isNormalRegistration}) => {
      const preferredTrackSchema = isNormalRegistration
        ? Yup.string()
        : Yup.string().required(formatMessage({id: 'field_required'}));
      return Yup.object().shape({
        first_name: Yup.string().required(
          formatMessage({id: 'error_first_name'}),
        ),
        last_name: Yup.string().required(
          formatMessage({id: 'error_last_name'}),
        ),
        email: Yup.string()
          .email(formatMessage({id: 'error_email_invalid'}))
          .required(formatMessage({id: 'error_email'}))
          .test(
            'disposableEmail',
            formatMessage({id: 'error_email_disposable'}),
            (value) => {
              const emailDomain =
                value && value.substring(value.lastIndexOf('@') + 1);
              return !disposableEmail.includes(emailDomain);
            },
          ),
        password: Yup.string()
          .min(8, formatMessage({id: 'password_number_chars'}))
          .required(formatMessage({id: 'field_required'})),
        preferred_track: preferredTrackSchema,
      });
    },
  }),
);

export default enhance(SignUpForm);
