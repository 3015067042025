import redirectIfAuthenticated from 'core/libs/hoc/redirectIfAuthenticated';
import SignUp from 'modules/auth/containers/SignUp';
import React from 'react';

const RegisterPage = (props) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <SignUp {...props} isNormalRegistration />;
};

export default redirectIfAuthenticated(RegisterPage);
